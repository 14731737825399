export enum StatoEnum {
  Attivo = 'Attivo',
  AttesaDiConferma = 'AttesaDiConferma',
  Sospeso = 'Sospeso',
  CambioPassword = 'CambioPassword',
  PrimoAccesso = 'PrimoAccesso',
}
export const Stato = [
  'Attivo',
  'AttesaDiConferma',
  'Sospeso',
  'CambioPassword',
  'PrimoAccesso',
];
