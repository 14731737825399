import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UpgradeModalComponent} from "./components/upgrade-modal.component";

@Injectable({providedIn: 'root'})
export class ModalManagerService {
  private bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService
  ) {
  }


  public show(planName: string) {
    this.bsModalRef = this.modalService.show(UpgradeModalComponent, {
      initialState: {
        planName
      }
    });
  }

}
