import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {GetMembroDto} from '../../../shared/dto/membro/get-membro.dto';
import {CreateMembroDto} from '../../../shared/dto/membro/create-membro.dto';
import {GetQuotaAssociativaDto} from '../../../shared/dto/quotaAssociativa/get-quota-associativa.dto';
import {CreateQuotaAssociativaDto} from '../../../shared/dto/quotaAssociativa/create-quota-associativa.dto';
import {GetAssociazioneDto} from '../../../shared/dto/associazione/get-associazione.dto';
import {AuthService} from '../../../shared/auth.service';
import {UpdateAssociazioneDto} from '../../../shared/dto/associazione/update-associazione.dto';
import {saveAs} from 'file-saver';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationManagerService} from '../../../shared/notification-manager.service';
import {GetSubscriptionDto} from "../../../shared/dto/associazione/get-subscription.dto";
import {GetAutologinUrlDto} from "../../../shared/dto/associazione/get-autologin-url.dto";

const apiUrl = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class AssociazioneService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notification: NotificationManagerService,
    private sanitizer: DomSanitizer) {
  }

  public async getSubscription(): Promise<GetSubscriptionDto> {
    return await this.http.get<GetSubscriptionDto>(`${apiUrl}/subscription`).toPromise();
  }

  public async getAutologinUrl(): Promise<GetAutologinUrlDto> {
    return this.http.get<GetAutologinUrlDto>(`${apiUrl}/autologin`).toPromise();
  }

  public async getAssociazione(): Promise<GetAssociazioneDto> {
    return await this.http.get<GetAssociazioneDto>(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}`).toPromise();
  }

  public async editAssociazione(id: string, associazione: UpdateAssociazioneDto): Promise<GetAssociazioneDto> {
    return await this.http.put<GetAssociazioneDto>(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}`, associazione).toPromise();
  }

  async uploadStatuto(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('statuto', fileToUpload, fileToUpload.name);
    return this.http.post(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/statuto`, formData).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }
  async deleteStatuto() {
    return this.http.delete(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/statuto`).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }
  async uploadRegolamento(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('regolamento', fileToUpload, fileToUpload.name);
    return this.http.post(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/regolamento`, formData).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }
  async deleteRegolamento() {
    return this.http.delete(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/regolamento`).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }

  async uploadLogo(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('logo', fileToUpload, fileToUpload.name);
    return this.http.post(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/logo`, formData).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }
  async deleteLogo() {
    return this.http.delete(`${apiUrl}/associazione/${this.authService.getUser().membro.idAssociazione}/logo`).toPromise().catch(err => {this.notification.httpErrorCustom(err); return null; });
  }
}
