// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { RuoloEnum } from '../app/shared/enums/ruolo.enum';

export const environment = {
  production: false,
  buyUrl: "https://m3czy1d9nd.execute-api.eu-central-1.amazonaws.com/dev",
  SCARF_ANALYTICS: false,
  adminRoot: '/app',
  apiUrl: 'https://app.soci.cloud:3000',
  wpUrl: 'https://soci.cloud',
  wpShopUrl: 'https://www.soci.cloud/#pricing',
  wpAccountUrl: 'https://soci.cloud/account',
  wpUpgradeUrl: 'https://www.soci.cloud/registrazione',

  defaultMenuType: 'menu-sub-hidden',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'confrasys-themecolor',
  isMultiColorActive: false,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  Radius Options:
  'flat', 'rounded'
  */
  defaultColor: 'light.blueolympic',
  defaultRadius: 'flat',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'confrasys-radius',
  isAuthGuardActive: true,
  defaultRole: RuoloEnum.Utente,
  minPasswordLength: 7,
  timeAfterRedirect: 2000,
  regexValuta: '^[0-9]{1,3}(?:.?[0-9]{3})*(\\,[0-9]{1,2})?$'
};
