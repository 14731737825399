export enum RuoloEnum {
  Amministratore = 'Amministratore',
  Operatore = 'Operatore',
  Segretario = 'Segretario',
  Cassiere = 'Cassiere',
  Utente = 'Utente',
}
export const Ruolo = [
  'Amministratore',
  'Operatore',
  'Segretario',
  'Cassiere',
  'Utente',
];
