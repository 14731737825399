import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';

import {getUserRole} from 'src/app/utils/util';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {LoginUserDto} from './dto/auth/login-user.dto';
import {LoginUserResponseDto} from './dto/auth/login-user-response.dto';
import {GetMembroDto} from './dto/membro/get-membro.dto';
import {ChangePasswordDto, ResetPasswordDto, SendVerificationCodeDto} from './dto/auth/change-password.dto';
import {NotificationManagerService} from './notification-manager.service';
import {MembroManagerService} from "../views/app/membri/membro-manager.service";
import {EmailAccountsDto} from "./dto/auth/email-accounts.dto";
import {SigninAssociazioneDto} from "./dto/auth/signin-associazione.dto";

const apiUrl = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentUserSubject: BehaviorSubject<LoginUserResponseDto>;
  public currentUser: Observable<LoginUserResponseDto>;

  constructor(
    private http: HttpClient,
    private notification: NotificationManagerService,
    private membroManagerService: MembroManagerService
  ) {
    this.currentUserSubject = new BehaviorSubject<LoginUserResponseDto>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginUserResponseDto {
    return this.currentUserSubject.value;
  }

  login(credentials: LoginUserDto) {
    return this.http.post<LoginUserResponseDto>(apiUrl + '/login', credentials).pipe(map((res) => {
      localStorage.setItem('currentUser', JSON.stringify({
        token: res.token,
        membro: res.membro
      }));
      this.currentUserSubject.next({
        token: res.token,
        membro: res.membro
      });
      return res;
    }));
  }

  signOut() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  forgotPassword(body: SendVerificationCodeDto): Observable<any> {
    return this.http.post(`${apiUrl}/reset-password/send-verification-code`, body, {observe: 'response'});
  }

  emailAccounts(body: EmailAccountsDto): Observable<any> {
    return this.http.post(`${apiUrl}/email-accounts`, body, {observe: 'response'});
  }

  resetPassword(body: ResetPasswordDto): Promise<any> {
    return this.http.post(`${apiUrl}/reset-password/change-password`, body, {observe: 'response'}).toPromise();
  }

  async changePassword(body: ChangePasswordDto): Promise<any> {
    const res = await this.http.post(`${apiUrl}/change-password`, body, {observe: 'response'}).toPromise();
    if (res) {
      const membro = await this.membroManagerService.getMembro(this.currentUserValue.membro.id);
      if (membro) {
        localStorage.setItem('currentUser', JSON.stringify({
          token: this.currentUserValue.token,
          membro
        }));
        this.currentUserSubject.next({
          token: this.currentUserValue.token,
          membro
        });
      }
    }
    return res;
  }


  getUser(): LoginUserResponseDto {
    return this.currentUserSubject.value;
  }

  activateAccount(idAccount: string, verificationCode: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', verificationCode);
    return this.http.get(`${apiUrl}/activate-account/${idAccount}`, {params, observe: 'response'});
  }

  async checkAlias(alias: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('alias', alias);
    return await this.http.get(`${apiUrl}/check-alias`, {params}).toPromise();
  }

  async checkUserSubscription(wpUserId: number): Promise<any> {
    return this.http.get(`${apiUrl}/check-pre-signup/${wpUserId}`).toPromise();
  }

  async signin(body: SigninAssociazioneDto): Promise<any> {
    return await this.http.post(`${apiUrl}/signin`, body).toPromise();
  }
}
