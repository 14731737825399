import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GetMembroDto } from '../../../shared/dto/membro/get-membro.dto';
import { CreateMembroDto } from '../../../shared/dto/membro/create-membro.dto';
import { GetQuotaAssociativaDto } from '../../../shared/dto/quotaAssociativa/get-quota-associativa.dto';
import { CreateQuotaAssociativaDto } from '../../../shared/dto/quotaAssociativa/create-quota-associativa.dto';
import { FilterMembriDto } from '../../../shared/dto/membro/filter-membri.dto';
import { NotificationManagerService } from '../../../shared/notification-manager.service';
const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class MembroManagerService {
  constructor(private http: HttpClient, private notification: NotificationManagerService) { }

  public async getMembriFilter(filter: FilterMembriDto): Promise<GetMembroDto[]> {
    let params = new HttpParams();
    if (filter) {
      params = params.append('type', filter.type);
      if (filter.genere)
        params = params.append('genere', filter.genere);
      if (filter.qualifica)
        params = params.append('qualifica', filter.qualifica);
      if (filter.sezione)
        params = params.append('sezione', filter.sezione);
      if (filter.ruolo)
        params = params.append('ruolo', filter.ruolo);
      if (filter.residente)
        params = params.append('residente', filter.residente.toString());
      if (filter.vedovanza)
        params = params.append('vedovanza', filter.vedovanza.toString());
      if (filter.deceduto)
        params = params.append('deceduto', filter.deceduto.toString());
      if (filter.annoRiferimentoQuote)
        params = params.append('annoRiferimentoQuote', filter.annoRiferimentoQuote.toString());
      if (filter.query)
        params = params.append('query', filter.query.toString());

    }
    return await this.http.get<GetMembroDto[]>(`${apiUrl}/membri`, { params }).toPromise();
  }
  public getMembriQuery(query: string): Observable<GetMembroDto[]> {
    return this.http.get<GetMembroDto[]>(`${apiUrl}/membri`);
  }
  public async getAssociati(): Promise<GetMembroDto[]> {
    return await this.http.get<GetMembroDto[]>(`${apiUrl}/membri/associati`).toPromise();
  }
  public async getAspiranti(): Promise<GetMembroDto[]> {
    return await this.http.get<GetMembroDto[]>(`${apiUrl}/membri/aspiranti`).toPromise();
  }
  public async getVedovi(): Promise<GetMembroDto[]> {
    return await this.http.get<GetMembroDto[]>(`${apiUrl}/membri/vedovi`).toPromise();
  }

  public async getMembro(idMembro: string): Promise<GetMembroDto> {
    return await this.http.get<GetMembroDto>(`${apiUrl}/membri/${idMembro}`).toPromise();
  }
  public async editMembro(idMembro: string, membro: Partial<CreateMembroDto>): Promise<GetMembroDto> {
    return await this.http.put<GetMembroDto>(`${apiUrl}/membri/${idMembro}`, membro).toPromise();
  }
  public async createMembro(membro: CreateMembroDto): Promise<GetMembroDto> {
    return await this.http.post<GetMembroDto>(`${apiUrl}/membri`, membro).toPromise();
  }
  public async deleteMembro(idMembro: string): Promise<GetMembroDto> {
    return await this.http.delete<GetMembroDto>(`${apiUrl}/membri/${idMembro}`).toPromise();
  }

  public async getQuoteAssociative(idMembro: string): Promise<GetQuotaAssociativaDto[]> {
    return await this.http.get<GetQuotaAssociativaDto[]>(`${apiUrl}/membri/${idMembro}/quote-associative`).toPromise();
  }
  public async createQuotaAssociativa(idMembro: string, quotaAssociativa: CreateQuotaAssociativaDto): Promise<GetQuotaAssociativaDto> {
    return await this.http.post<GetQuotaAssociativaDto>(`${apiUrl}/membri/${idMembro}/quote-associative`, quotaAssociativa).toPromise();
  }
  public async getQuotaAssociativa(idMembro: string, idQuotaAssociativa: string): Promise<GetQuotaAssociativaDto> {
    return await this.http.get<GetQuotaAssociativaDto>(`${apiUrl}/membri/${idMembro}/quote-associative/${idQuotaAssociativa}`).toPromise();
  }
  public async editQuotaAssociativa(idMembro: string, idQuotaAssociativa: string, quotaAssociativa: Partial<CreateQuotaAssociativaDto>): Promise<GetQuotaAssociativaDto> {
    return await this.http.put<GetQuotaAssociativaDto>(`${apiUrl}/membri/${idMembro}/quote-associative/${idQuotaAssociativa}`, quotaAssociativa).toPromise();
  }
  public async deleteQuotaAssociativa(idMembro: string, idQuotaAssociativa: string): Promise<GetQuotaAssociativaDto> {
    return await this.http.delete<GetQuotaAssociativaDto>(`${apiUrl}/membri/${idMembro}/quote-associative/${idQuotaAssociativa}`).toPromise();
  }
}
