import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {BehaviorSubject, from, Observable} from 'rxjs';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NotificationsService, NotificationType} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';

const apiUrl = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class NotificationManagerService {

  constructor(private notifications: NotificationsService, private translate: TranslateService) {
  }

  public error(code: string) {
    this.notifications.create(this.getErrorTitle(code), this.getErrorMessage(code), NotificationType.Error, {
      theClass: 'outline',
      timeOut: 6000,
      showProgressBar: false
    });
  }

  public httpError(error: HttpErrorResponse) {
    if (error.error.code !== undefined) {
      return this.notifications.create(this.getErrorTitle('GENERIC'), this.getErrorMessage(error.error.code), NotificationType.Error, {
        theClass: 'outline',
        timeOut: 6000,
        showProgressBar: false
      });
    }
    if (error.error.message) {
      return this.notifications.create(this.getErrorTitle('GENERIC'), error.error.message, NotificationType.Error, {
        theClass: 'outline',
        timeOut: 6000,
        showProgressBar: false
      });
    }
    switch (error.error.statusCode) {
      case 500: {
        this.error('GENERIC');
        break;
      }
      case 404: {
        this.error('ENTITY_NOT_FOUND');
        break;
      }
      case 400: {
        this.error('BAD_REQUEST');
        break;
      }
      default: {
        this.error('GENERIC');
        break;
      }
    }
    return null;
  }

  public httpErrorCustom(error: HttpErrorResponse) {
    switch (error.error.statusCode) {
      case 500: {
        this.error('GENERIC');
        break;
      }
      default: {
        this.notifications.create(this.getErrorTitle('GENERIC'), error.error.message, NotificationType.Error, {
          theClass: 'outline',
          timeOut: 6000,
          showProgressBar: false
        });
      }
    }
    return null;
  }
  public success(code: string) {
    this.notifications.create(this.getSuccessTitle(code), this.getSuccessMessage(code), NotificationType.Success, {
      theClass: 'outline',
      timeOut: 6000,
      showProgressBar: false
    });
  }

  public getErrorTitle(code: string) {
    return code !== undefined ? this.translate.instant('error.' + code + '.title') : this.translate.instant('error.GENERIC.title');
  }

  private getErrorMessage(code: string) {
    return code !== undefined ? this.translate.instant('error.' + code + '.message') : this.translate.instant('error.GENERIC.message');
  }

  private getSuccessTitle(code: string) {
    return code !== undefined ? this.translate.instant('success.' + code + '.title') : this.translate.instant('success.GENERIC.title');
  }

  private getSuccessMessage(code: string) {
    return code !== undefined ? this.translate.instant('success.' + code + '.message') : this.translate.instant('success.GENERIC.message');
  }
}
