import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import {AuthInterceptor} from "./shared/auth.interceptor";
import {HttpErrorInterceptor} from './shared/errors.interceptor';
import {SharedModule} from './shared/shared.module';
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {UpgradeModalComponent} from "./shared/components/upgrade-modal.component";
import { XyzComponent } from './xyz/xyz.component';
import { ComponentNameComponent } from './component-name/component-name.component';

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    UpgradeModalComponent,
    XyzComponent,
    ComponentNameComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

