import {Component, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {environment} from '../../../environments/environment';
import { Location } from '@angular/common'
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-upgrade',
    template: `
        <div class="modal-header">
            <h4 class="modal-title pull-left">Funzionalità bloccata!</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                Per usufruire di questa funzionalità hai bisogno del piano {{planName}}!
            </p>
            <button type="button" class="btn btn-outline-primary" (click)="openWpPlans()">Scopri di più!</button>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" (click)="openUpgradePage()">UPGRADE</button>
            <button type="button" class="btn btn-outline-danger" (click)="bsModalRef.hide()">Chiudi</button>
        </div>
    `
})

export class UpgradeModalComponent implements OnInit {
    planName: string;

    constructor(
      public bsModalRef: BsModalRef,
      private router: Router) {
    }

    ngOnInit() {
      this.router.navigate([`${environment.adminRoot}`]);
    }

    openWpPlans() {
      window.open(environment.wpShopUrl, "_blank");
    }
    openUpgradePage() {
      window.open(environment.wpUpgradeUrl, "_blank");
    }
}
