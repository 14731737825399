import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorComponent} from '../views/error/error.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {UnauthorizedComponent} from '../views/unauthorized/unauthorized.component';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {NotificationManagerService} from './notification-manager.service';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {FormQuotaAssociativaComponent} from "../components/forms/form-quota-associativa/form-quota-associativa.component";
import {DebounceClickDirective} from "./debounce-click.directive";
import {DoughnutChartComponent} from "../components/charts/doughnut-chart/doughnut-chart.component";
import {BsLocaleService} from "ngx-bootstrap/datepicker";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {ModalManagerService} from "./modal-manager.service";
import {BootstrapModule} from "../components/bootstrap/bootstrap.module";
import {UiModalsContainersModule} from "../containers/ui/modals/ui.modals.containers.module";
import {TimepickerModule} from "ngx-bootstrap/timepicker";

@NgModule({
  declarations: [
    ErrorComponent,
    UnauthorizedComponent,
    DebounceClickDirective,
  ],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule,
    SimpleNotificationsModule.forRoot(),
    ModalModule
  ],
  providers: [
    NotificationManagerService,
    ModalManagerService,
    BsModalService,
    BsLocaleService
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    SimpleNotificationsModule,
    DebounceClickDirective,
    ModalModule
  ],
})
export class SharedModule {
}
