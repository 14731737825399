import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {NotificationManagerService} from "./notification-manager.service";
import {Injectable} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {ModalInnerComponent} from "../containers/ui/modals/modal-component/modal-inner-component";
import {initialState} from "ngx-bootstrap/timepicker/reducer/timepicker.reducer";
import {ModalManagerService} from "./modal-manager.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private notificationManagerService: NotificationManagerService,
    private modalManagerService: ModalManagerService,
    private translate: TranslateService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          console.log(error);
          if (error.error?.code === 'INVALID_PLAN') {
            this.modalManagerService.show(error.error?.requiredPlan.title);
          } else {
            this.notificationManagerService.httpError(error);
          }
          return throwError(error);
        })
      );
  }
}
