import { environment } from 'src/environments/environment';


export const getThemeColor = () => {
    let color = environment.defaultColor;
    try {
        color = localStorage.getItem(environment.themeColorStorageKey) || environment.defaultColor
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeColor -> error", error)
        color = environment.defaultColor
    }
    return color;
}
export const setThemeColor = (color) => {
    try {
        if (color) {
            localStorage.setItem(environment.themeColorStorageKey, color);
        } else {
            localStorage.removeItem(environment.themeColorStorageKey)
        }
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeColor -> error", error)
    }
}
export const getThemeRadius = () => {
    let radius = environment.defaultRadius;
    try {
        radius = localStorage.getItem(environment.themeRadiusStorageKey) || environment.defaultRadius;
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeRadius -> error", error);
        radius = environment.defaultRadius
    }
    return radius;
}
export const setThemeRadius = (radius) => {
    try {
        localStorage.setItem(environment.themeRadiusStorageKey, radius);
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeRadius -> error", error)
    }
}

export const getThemeLang = () => {
    let lang = 'it-IT';
    try {
        lang = localStorage.getItem('theme_lang') || 'it-IT';
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : getThemeLang -> error", error)
        lang = 'it-IT'
    }
    return lang;
}
export const setThemeLang = (lang) => {
    try {
        localStorage.setItem('theme_lang', lang);
    } catch (error) {
        console.log(">>>> src/app/utils/util.js : setThemeLang -> error", lang)
    }
}

export const getUserRole = () => {
  let role = environment.defaultRole;
  try {
      role = localStorage.getItem('theme_user_role') || environment.defaultRole;
  } catch (error) {
      console.log(">>>> src/app/utils/util.js : getUserRole -> error", error);
      role = environment.defaultRole
  }
  return role;
}
export const setUserRole = (role) => {
  try {
      localStorage.setItem('theme_user_role', role);
  } catch (error) {
      console.log(">>>> src/app/utils/util.js : setUserRole -> error", role)
  }
}
export const parseValuta = (value) => {
  if (value === undefined || value === null)
    return null;
  return parseFloat(value.replace('.', '').replace(',', '.')).toFixed(2);
}
export const displayValuta = (value) => {
  if (value === undefined || value === null)
    return null;
  if (typeof value === "string")
    value = parseFloat(value);
  return value.toFixed(2).toString().replace('.', ',');
}
export const getSimplyDate = (date) => {
  let data;
  if (date !== null)
    data = new Date(date);
  else
    data = new Date();
  const year = data.getFullYear();
  const month = data.getMonth();
  const day = data.getDate();
  return new Date(year, month, day);
}
